
.link-card {
    display: flex;
    align-items: center;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.42);
    border-radius: 10px;
    column-gap: 15px;
    padding: 15px;
    margin-bottom: 20px;
}

/*linear-gradient(124deg, #bcfcff, #b4acff)*/

.link-card:hover{
    cursor: pointer;
}

.link-card-content {

}
