
.form-input-modifier {
    position: absolute;
    top: 0;
    width: 15%;
    height: 100%;
    opacity: 0;
    background-color: rgba(128, 128, 128, 0.47);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
}

.form-input-modifier:hover {
    opacity: 1;
    transition: 0.2s;
}

.form-input-up {
    left: 55%;
}

.form-input-down {
    left: 70%;
}

.form-input-delete {
    left: 85%;
    font-size: 2em;
    background-color: rgba(255, 75, 75, 0.47);
}



