.event-actions-group {
    /*border: 1px solid rgba(30, 144, 255, 0.7);*/
    /*background-color: rgba(135, 206, 250, 0.19);*/
    /*padding: 10px;*/
    margin: 10px 0;
}

.event-actions-group-empty {
    display: none;
}
.admin-actions {
    cursor: pointer;
}

.student-actions {
    cursor: pointer;
}

.teacher-actions {
    cursor: pointer;
}
