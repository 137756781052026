.affix {
    position: sticky;
    top: 40px;
    z-index: 1000;

    @media (max-width: 991px) {
        display: none;
    }
}

.move {
    display: none;
    @media (max-width: 991px) {
        display: contents;
    }
}