.wrapper {
  container-type: inline-size;
}

.questionCard {
  width: 700px;
  margin: 20px auto;
  padding: 30px;
  border-radius: 10px;

  @container (max-width: 700px) {
    width: 100%;
  }
}

.answerForm {
  margin-top: 20px;
}

.flag {
  border: none;
  margin-left: auto;

  :hover {
    -webkit-text-stroke: 0.5px;
    cursor: pointer;
  }
}

.radio {
  :hover {
    cursor: pointer;
  }
}
