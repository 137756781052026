.menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0.5rem;
  padding: 1rem;
  border-bottom: 1px solid #d4d4d4;
  background-color: hsl(0, 0%, 97%);
  overflow-x: auto;
  scrollbar-width: thin;
}

.button {
  min-width: 2em;
  border: 1px solid #d4d4d4;
  border-radius: 10px;
  padding: 0.2em;
  background-color: white;

  &.isActive {
    background-color: hsl(0, 0%, 90%);
  }
}
