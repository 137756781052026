
/* common */
.add {
    border: 3px solid black;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 10px;
    padding-left: 10px;
    cursor: pointer;
    transition: 0.1s;

}

.add {
    opacity: 1;
    background-color: rgb(255, 193, 7);
}


