.wrapper {
  container-type: inline-size;
}

.nav {
  margin: auto;
  width: 250px;

  @container (max-width: 200px) {
    width: 100%;
  }
}

.scroll {
  overflow-y: auto;
  scrollbar-width: thin;
  height: 250px;
}

.flag {
  margin-left: auto;
  border: none;

  :hover {
    -webkit-text-stroke: 0.5px;
    cursor: pointer;
  }
}

.item {
  border: none;

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
