.school-view-page {
    border: 2px solid rgba(128, 128, 128, 0.45);
    background-color: rgba(244, 234, 136, 0.11);
    padding: 15px;
    border-radius: 15px;
}


.teachers {
    color: rgb(0, 193, 62);
}
