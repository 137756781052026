
.event-card {
    display: flex;
    align-items: center;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.42);
    border-radius: 10px;
    column-gap: 15px;
}

.event-card:hover{
    cursor: pointer;
}
.event-card-details {
}

.event-card-img {
    object-fit: cover;
    height: 50px;
    width: 50px;
}
